<template>
  <v-main>
    <v-container class="fill-height primary" fluid>
      <router-view/>
    </v-container>
  </v-main>
</template>

<script lang="js">
export default {
  name: 'Authorisation',
};
</script>
